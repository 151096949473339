export const sheets = [
  {
    table: 'orr_events',
    name: 'Events',
    columns: [
      'Group',
      'Name',
      'StartDate',
      'EndDate',
    ],
  },
  {
    table: 'orr_stopwords',
    name: 'Stop Words',
    columns: [
      'Word',
    ],
  },
];

export const toJsonTransformer = (data, name) => {

};

export const fromJsonTransformer = (data, name) => {
  const sheet = sheets.find((item) => item.name === name);
  const result = {};
  result[0] = { cells: {} };
  sheet.columns.forEach((item, index) => {
    result[0].cells[index] = {
      text: item, style: 0,
    };
  });

  data.forEach((item, index) => {
    const columIndex = index + 1;
    result[columIndex] = { cells: {} };
    sheet.columns.forEach((column, index) => {
      result[columIndex].cells[index] = {};
      result[columIndex].cells[index].text = item[column] ? item[column] : null;
    });
  });
  return result;
};
