<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-subtitle-1">
        <h1>Spreadsheet</h1>
      </v-col>
      <v-col style="text-align: right">
        <v-btn
          color="primary"
          @click="saveChanges"
        >
          Save Changes
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col cols="12">
        <div
          ref="spreadsheet"
          style="height: calc(83vh)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import Spreadsheet from 'x-data-spreadsheet';
import { fromJsonTransformer } from './lib/transformer';

let xs;

export default {
  props: ['message'],
  data: () => ({
    changed: false,
  }),
  mounted() {
    this.init();
  },
  methods: {
    saveChanges() {
      if (this.changed) {
        console.log('data', xs.getData());
      }
    },
    init() {
      const that = this;
      // eslint-disable-next-line no-undef
      const styles = [
        {
          bgcolor: '#f4f5f8',
          textwrap: true,
          font: {
            bold: true,
          },
        },
      ];
      const col = {
        minWidth: 200,
        width: 200,
      };
      const options = {
        showToolbar: true,
        showGrid: true,
        showContextmenu: false,
        styles: [
          {
            bgcolor: '#f4f5f8',
            textwrap: true,
          },
        ],
        view: {
          height: () => this.$refs.spreadsheet.clientHeight,
        },
        col,
      };
      const stopwordsRows = {
        0: {
          cells: {
            0: { text: 'Word', style: 0 },
          },
        },
        1: {
          cells: {
            0: { text: 'train' },
          },
        },
        2: {
          cells: {
            0: { text: 'driver' },
          },
        },
      };

      const eventRows = {
        0: {
          cells: {
            0: { text: 'Name', style: 0 },
            1: { text: 'Start Date', style: 0 },
            2: { text: 'End Date', style: 0 },
          },
        },
        1: {
          cells: {
            0: { text: '1st Covid Lockdown' },
          },
        },
        2: {
          cells: {
            0: { text: 'Christmas Day' },
          },
        },
      };
      // eslint-disable-next-line no-undef
      xs = x_spreadsheet(this.$refs.spreadsheet, options)
        .loadData([
          {
            name: 'Stop Words',
            rows: fromJsonTransformer([], 'Stop Words'),
            styles,
            col,
          },
          {
            name: 'Events',
            rows: fromJsonTransformer([{ Group: 'Covid Event' }], 'Events'),
            styles,
            col,
          },
        ]) // load data
        .change((data) => {
          that.changed = true;
          // console.log('data', xs.getData());
          // save data to db
        });
    },
  },
};
</script>
